<template>
  <FadeTransition>
    <div class="s-default-page-spacing s-default-flex-content">
      <FadeTransition>
        <ProgressBar />
      </FadeTransition>

      <FadeTransition>
        <div
          v-if="verificationStep === 1"
          class="flex flex-col justify-between md:flex-row"
        >
          <VerificationStep1
            :level="securityLevel"
            class="flex flex-1 flex-shrink-0"
            @continueStep="continueStep"
          />
          <div class="flex items-start mt-6 md:ml-16">
            <div class="flex items-center md:flex-row">
              <BaseIcon
                class="hidden md:block"
                size="30"
              >
                <IconInformation />
              </BaseIcon>
              <div
                class="flex items-center justify-center py-2 pl-3 pr-2 text-sm rounded md:text-base w-100 md:w-80 text-neutral-4 bg-neutral-9"
              >
                {{ $t("base.verificationStep1Info") }}
              </div>
            </div>
          </div>
        </div>
      </FadeTransition>

      <FadeTransition>
        <VerificationStep2
          v-if="verificationStep === 2"
          @cancel="cancelStep"
          @continueStep="continueStep"
        />
      </FadeTransition>
    </div>
  </FadeTransition>

  <FadeTransition>
    <VerificationQes
      v-if="verificationStep === 3"
      @completed="verifyQes"
    />
  </FadeTransition>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

import useTailwindTheme from "../../composables/LayoutAndStyle/useTailwindTheme";

import ProgressBar from "../../components/Common/ProgressBar";
import VerificationStep1 from "../../components/Verification/VerificationStep1";
import VerificationStep2 from "../../components/Verification/VerificationStep2";
import IconInformation from "../../components/icons/IconInformation";
import VerificationQes from "../../components/Verification/VerificationQes";
import FadeTransition from "../../components/Common/Transitions/FadeTransition";

export default {
  components: {
    FadeTransition,
    IconInformation,
    VerificationStep1,
    VerificationStep2,
    ProgressBar,
    VerificationQes,
  },
  props: {
    requestId: {
      type: String,
      default: "",
      required: true,
    },
    recipientId: {
      type: String,
      default: "",
      required: true,
    },
  },
  async setup() {
    const { t } = useI18n();
    const store = useStore();
    const { colors } = useTailwindTheme();
    const verificationStep = ref(1);
    const securityLevel = store.state.request.securityLevel;

    const completedVerification = computed(() => {
      return store.state.auth.completedVerification;
    });

    onMounted(() => verifyQes());

    function verifyQes() {
      console.log("VerificationMain mounted", store.getters['auth/isSignteqVerified'], store.getters['auth/isQESTodo']);

      if (store.getters['auth/isSignteqVerified'] && store.getters['auth/isQESTodo']) {
        verificationStep.value = 3;
      }
    }

    function continueStep(step) {
      verificationStep.value = 0;
      /* set timeout to give animation time to finish */
      setTimeout(() => {
        verificationStep.value = step;
        verifyQes();
      }, 400);
    }

    function cancelStep() {
      /* set timeout to give animation time to finish */
      verificationStep.value = 0;
      setTimeout(() => {
        verificationStep.value = 1;
      }, 400);
    }

    return {
      t,
      colors,
      verifyQes,
      verificationStep,
      securityLevel,
      continueStep,
      cancelStep,
      completedVerification,
    };
  },
};
</script>
