import AuthenticationService from "../services/AuthenticationService";
import apiClient from "../api";
import Cookies from "js-cookie";

export const auth = {
  namespaced: true,
  state: () => ({
    hash: undefined,
    completedVerification: false,
    authToken: undefined,
    qes: undefined,
  }),
  mutations: {
    setHash(state, hash) {
      state.hash = hash;
    },
    setAuthToken(state, token) {
      state.authToken = token;
    },
    setCompletedSignteqVerification(state, completedVerification) {
      state.completedVerification = completedVerification;
    },
    setQES(state, data) {
      state.qes = data;
    },
    setQESSignatureToken(state, signatureToken) {
      state.qes.signature_token = signatureToken;
    }
  },
  actions: {

    /**
     * Works for BASIC security level
     * Get and set auth token
     * @param commit
     * @param hash
     * @return {Promise<void>}
     */
    async authenticateBasic({ commit, state, rootState }) {
      const response = await AuthenticationService.authenticateToken(
        rootState.request.requestId,
        rootState.request.recipientId,
        { hash: state.hash }
      );

      if (response) {
        /* if response contains error -> show error message */
        if (response.errors && response.message) {
          commit("SET_ERROR", response, { root: true });
        }
        /* if response contains data -> set level and theming */
        if (response.data) {
          commit("auth/setAuthToken", response.data.access_token, {root: true});
          apiClient.defaults.headers.common = {
            Authorization: `Bearer ${response.data.access_token}`,
          };
        }
      }
    },


    /**
     * Part 1 of 2. If security level is advanced, use this step
     * Send phone number and pin (if necessary)
     * @param state
     * @param phoneNumber
     * @return Object
     */
    async startMFA({ state, rootState }, { phoneNumber, pin }){
      let auth = {
        hash: state.hash,
        phonenumber: phoneNumber,
      };
      if (rootState.request.securityLevel === "advanced") {
        auth = Object.assign(auth, { access_pin: pin });
      }
      return await AuthenticationService.startMFA(
        rootState.request.requestId,
        rootState.request.recipientId,
        auth
      );
    },

    /**
     * Part 2 of 2.
     * Use this for ADVANCED security level
     *
     * Send code received via sms.
     * Get and set auth token
     * @param code from use input
     *
     * @param commit
     * @param rootState
     * @param state
     * @return Object
     */
    async completeMFA({ commit, state, rootState }, { code }) {
      const response = await AuthenticationService.authenticateToken(
        rootState.request.requestId,
        rootState.request.recipientId,
        {
          hash: state.hash,
          code
        }
      );

      if (response.data) {
        commit("auth/setAuthToken", response.data.access_token, {root: true});
        apiClient.defaults.headers.common = {
          Authorization: `Bearer ${response.data.access_token}`,
        };
      } else {
        console.error("No Data given.");
      }

      return response;
    },
    setAndPersistHash({ commit, rootState}, hash){
      commit('setHash', hash);
      Cookies.set(`hash_${rootState.request.requestId}`, hash, { expires: 1 });
    }
  },
  getters: {
    /**
     * Is user verified?
     *
     * This is the case, if either the the security level is basic
     * or if the user completed the advanced verification
     * AND qes is completed or not required
     */
    isVerified(state, getters) {
      console.log("isVerified", !!(getters.isSignteqVerified && !getters.isQESTodo), getters.isQESTodo);
      return !!(getters.isSignteqVerified && !getters.isQESTodo);
    },

    isSignteqVerified(state, getters, rootState) {
      return (state.completedVerification || rootState.request.securityLevel === "basic")
    },

    isQESTodo(state) {
      console.log("isQESTodo", state.qes, (state.qes && !state.qes.signature_token));
      return (state.qes && !state.qes.signature_token)
    }
  },
};
