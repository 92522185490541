import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import camelCase from "lodash/camelCase";
import QESService from "../services/QESService";

const errorCodes = [
    'invalid_session_token',
    'identification_score_too_low',
    'certificate_expired',
    'wrong_otp',
    'maximum_attempts_exceeded'
];

export default function useQes(recipient) {
    const { t } = useI18n();
    const store = useStore();
    const initiated = ref(false);
    const confirming = ref(false);
    const error = ref(null);
    const phonenumber = ref(null);

    const errorText = computed(() => {
        return error.value
            ? t(`qes.errors.${camelCase(error.value)}`)
            : null;
    });

    const initiate = () => {
        QESService.initiate({
            sessionToken: recipient.qes_token,
            signatureId: recipient.qes_id,
            signatureToken: recipient.qes_signature_token,
        }).then(response => {
                phonenumber.value = response.data.phonenumber;
            })
            .catch(e => {
                error.value = errorCodes.includes(e.response.data.error)
                    ? e.response.data.error
                    : 'unexpected_error';
            })
            .finally(() => {
                initiated.value = true;
            });
    };

    const confirm = (otp, callback) => {
        confirming.value = true;

        let requestId = store.state.request.requestId;
        let documentId = store.state.documents.currentDocument.id;
        let fields = store.getters["documentEditor/fieldsForRecipient"];

        QESService.complete({
            sessionToken: recipient.qes_token,
            signatureId: recipient.qes_id,
            signatureToken: recipient.qes_signature_token,
        }, otp.value, requestId, documentId, fields)
            .then(response => {
                callback(response.data);
            })
            .catch(e => {
                error.value = errorCodes.includes(e.response.data.error)
                    ? e.response.data.error
                    : 'unexpected_error';
            })
            .finally(() => {
                confirming.value = false;
            });
    };

    return {
        initiate,
        confirm,

        initiated,

        confirming,

        phonenumber,
        errorText,
        error,
    };
}
